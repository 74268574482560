<template>
  <div>
    <h6 v-if="!hideTitle" class="text-info">
      Linked Test Cases
    </h6>

    <b-list-group v-if="allTests && allTests.length > 0" flush>
      <b-list-group-item
        v-for="(testCase, index) in allTests" :key="index"
        class="flex-column align-items-start cursor-pointer list-item py-75"
        @click.stop="routeToTestCase(testCase.id)"
        @click.middle.stop="routeToTestCaseNewTab(testCase.id)"
      >
        <div class="d-inline-flex w-100 justify-content-start align-middle">
          <feather-icon
            v-if="testCase.lastTestExecution && testCase.lastTestExecution.result.toUpperCase() === 'PASSED'"
            class="bg-success text-white p-25 rounded-circle"
            icon="CheckIcon"
            size="18"
          />
          <feather-icon
            v-else-if="testCase.lastTestExecution && testCase.lastTestExecution.result.toUpperCase() === 'FAILED'"
            class="bg-danger text-white p-25 rounded-circle"
            icon="XIcon"
            size="18"
          />
          <feather-icon
            v-else
            class="bg-secondary text-white p-25 rounded-circle"
            icon="MinusIcon"
            size="18"
          />
          <h6 class="text-primary ml-50">
            {{ testCase.ref_id }}
          </h6>
        </div>
        <pre v-if="!hideName && testCase.name" class="py-25">{{ testCase.name }}</pre>

        <div v-if="testCase.lastTestExecution" class="flex-column w-100">
          <div class="d-flex w-100 justify-content-start align-middle ml-2">
            <feather-icon
              icon="ToolIcon"
              size="16"
            />
            <span class="ml-50 font-small-3">
              Build:
              <span :class="testCase.lastTestExecution.build_name ? '' : 'text-muted'">
                {{ testCase.lastTestExecution.build_name || 'n/a' }}
              </span>
            </span>
          </div>

          <div class="d-flex w-100 justify-content-start align-middle mt-25 ml-2">
            <feather-icon
              icon="CalendarIcon"
              size="16"
            />
            <span class="ml-50 font-small-3">
              Last Run:
              {{ testCase.lastTestExecution.datetime | diffForHumansMaxTwoWeeks }}
            </span>
          </div>
        </div>
      </b-list-group-item>
    </b-list-group>
    <div v-else class="px-4">
      <span class="text-muted font-small-4">
        No linked test cases
      </span>
    </div>
  </div>
</template>

<script>
import { useRouter } from '@core/utils/utils'

export default {
  name: 'ListTestCases',
  components: {},
  props: {
    allTests: {
      type: Array,
      default: () => [],
    },
    hideTitle: {
      type: Boolean,
      default: false,
    },
    hideName: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const { route, router } = useRouter()

    const routeToTestCase = testCaseId => {
      router.push({
        name: 'model_test_focus',
        params: { testId: testCaseId },
      })
    }

    const routeToTestCaseNewTab = testCaseId => {
      const routeData = router.resolve({
        name: 'model_test_focus',
        params: { ...route.params, testId: testCaseId },
      })
      window.open(routeData.href, '_blank')
    }

    return {
      routeToTestCase,
      routeToTestCaseNewTab,
    }
  },
}
</script>

<style scoped lang="scss">
.list-item {
  border-left: 1px solid;
  border-right: 1px solid;
  border-radius: 1rem;
}
.list-item:not(:hover) {
  background-color: rgba(0, 0, 0, 0.1) !important;
}
</style>
