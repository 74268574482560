<template>
  <div id="BuildConfigurationView">
    <div class="w-100 d-inline-flex justify-content-between align-items-center">
      <h2>
        {{ titleObj.name }}
        <span class="font-small-4">({{ titleObj.version }})</span>
      </h2>

      <div>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          title="Refresh the Configuration, and abandon unsaved changes"
          variant="outline-info"
          size="sm"
          class="mr-50"
          @click="fetchConfiguration"
        >
          <feather-icon icon="RefreshCwIcon" />
          Refresh
        </b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          title="Save changes to the Configuration"
          variant="success"
          size="sm"
          @click="onSaveDetails"
        >
          <feather-icon icon="SaveIcon" />
          Save Changes
        </b-button>
      </div>
    </div>

    <hr class="mt-50 mb-1">

    <!-- Details card -->
    <div class="w-100 d-inline-flex justify-content-center">
      <b-card class="w-75">
        <div class="w-100 d-inline-flex justify-content-between">
          <div class="w-100">
            <!-- Configuration Name -->
            <b-form-group label="Configuration name" label-for="config-name">
              <b-form-input
                id="config-name"
                v-model="configObj.name"
                placeholder="Enter the name of the Configuration..."
                autocomplete="off"
                autocapitalize="characters"
                required
              />
            </b-form-group>

            <!-- Version -->
            <b-form-group label="Version" label-for="config-version">
              <b-form-input
                id="config-version"
                v-model="configObj.version"
                type="text"
                placeholder="Configuration version..."
                autocomplete="off"
                autocapitalize="off"
              />
            </b-form-group>

            <!-- Effective Date -->
            <b-form-group label="Date effective from" label-for="config-date">
              <b-input-group>
                <b-form-input
                  id="config-date"
                  v-model="configObj.effective_from"
                  type="text"
                  placeholder="What date does this configuration become available or effectve? (YYYY-MM-DD)"
                  autocomplete="off"
                />
                <b-input-group-append>
                  <b-form-datepicker
                    v-model="configObj.effective_from"
                    button-only
                    right
                    size="sm"
                    button-variant="info"
                    locale="en-AU"
                    aria-controls="config-date"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </div>

          <div class="w-100 ml-2">
            <!-- Description -->
            <b-form-group label="Description" label-for="config-desc">
              <b-form-textarea
                id="config-desc"
                v-model="configObj.description"
                placeholder="Describe the Configuration..."
                rows="7"
              />
            </b-form-group>
          </div>
        </div>
      </b-card>
    </div>

    <div class="w-100 d-inline-flex justify-content-between">
      <!-- Components -->
      <b-card class="w-100">
        <list-group-entity
          label="Components"
          :entity-array="configObj.components"
          modal="associator-generic-components-edit"
        />
      </b-card>

      <!-- Tests -->
      <b-card class="w-100 mx-1">
        <div class="w-100 d-inline-flex justify-content-between mb-1">
          <b-badge pill class="mr-1" :variant="configObj.tests.length > 0 ? 'info' : 'muted'">
            {{ configObj.tests.length ? configObj.tests.length : '-' }}
          </b-badge>

          <div class="d-inline-flex justify-content-between w-100">
            <span class="font-weight-bold">Test Cases</span>
            <b-link
              title="Link Test Cases"
              class="mr-2"
              @click.stop="$bvModal.show('associator-generic-tests-edit')"
            >
              <feather-icon icon="LinkIcon" size="16" class="text-success" />
            </b-link>
          </div>
        </div>

        <list-test-cases :all-tests="configObj.tests" hide-title />
      </b-card>

      <!-- Interfaces -->
      <b-card class="w-100">
        <list-group-interface
          label="Interfaces"
          :entity-array="configObj.interfaces"
          modal="associator-generic-interfaces-edit"
        />
      </b-card>

      <!-- Instances -->
      <!--<b-card class="w-100">-->
      <!--  <list-group-instance-->
      <!--    label="Instances"-->
      <!--    :entity-array="configObj.instances"-->
      <!--    modal="associator-generic-instances-edit"-->
      <!--  />-->
      <!--</b-card>-->
    </div>

    <div id="modal__ConfigEditModalsContainer">
      <!-- Components -->
      <associator-generic
        name="Components"
        suffix="-edit"
        :associated-items="configObj.components.map(item => {
          return {
            value: {
              id: item.id,
              toSortBy: item.name,
            },
            text: item.name,
          }
        })"
        :all-items="allComponents.map(item => {
          return {
            value: {
              id: item.id,
              toSortBy: item.name,
            },
            text: item.name,
          }
        })"
        @associated="linkComponents"
      />

      <!-- Interfaces -->
      <associator-generic
        name="Interfaces"
        suffix="-edit"
        :associated-items="configObj.interfaces.map(item => {
          return {
            value: {
              id: item.id,
              toSortBy: item.name,
            },
            text: item.display_id + ' - ' + item.name,
          }
        })"
        :all-items="allInterfaces.map(item => {
          return {
            value: {
              id: item.id,
              toSortBy: item.name,
            },
            text: item.display_id + ' - ' + item.name,
          }
        })"
        @associated="linkInterfaces"
      />

      <!-- Test Cases -->
      <associator-generic
        name="Tests"
        suffix="-edit"
        :associated-items="configObj.tests.map(item => {
          return {
            value: {
              id: item.id,
              toSortBy: item.name,
            },
            text: item.ref_id + ' - ' + item.name,
          }
        })"
        :all-items="allTests.map(item => {
          return {
            value: {
              id: item.id,
              toSortBy: item.name,
            },
            text: item.ref_id + ' - ' + item.name,
          }
        })"
        @associated="linkTests"
      />

      <!-- Instances -->
      <!--<associator-generic-->
      <!--  name="Instances"-->
      <!--  suffix="-edit"-->
      <!--  :associated-items="configObj.instances.map(item => {-->
      <!--    return {-->
      <!--      value: {-->
      <!--        id: item.id,-->
      <!--        toSortBy: item.name,-->
      <!--      },-->
      <!--      text: item.name + ' (' + item.component + ')',-->
      <!--    }-->
      <!--  })"-->
      <!--  :all-items="allInstances.map(item => {-->
      <!--    return {-->
      <!--      value: {-->
      <!--        id: item.id,-->
      <!--        toSortBy: item.name,-->
      <!--      },-->
      <!--      text: item.name + ' (' + item.component + ')',-->
      <!--    }-->
      <!--  })"-->
      <!--  @associated="linkInstances"-->
      <!--/>-->
    </div>
  </div>
</template>

<script>
import {
  computed,
  onMounted,
  onUnmounted,
  ref,
} from '@vue/composition-api'
import axiosIns from '@/libs/axios'
import router from '@/router'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AssociatorGeneric from '@/components/Generic/Associators/AssociatorGeneric.vue'
import ListGroupEntity from '@/components/Forms/ListGroups/ListGroupEntity.vue'
import ListGroupInterface from '@/components/Forms/ListGroups/ListGroupInterface.vue'
import ListTestCases from '@/views/RequirementsTable/components/ListTestCases.vue'
// import ListGroupInstance from '@/components/Forms/ListGroups/ListGroupInstance.vue'

export default {
  name: 'Configuration',
  directives: { Ripple },
  components: {
    ListTestCases,
    AssociatorGeneric,
    ListGroupEntity,
    ListGroupInterface,
    // ListGroupInstance,
  },
  setup(props, context) {
    const { modelId, configurationId } = router.currentRoute.params
    const params = { model: modelId }

    onMounted(() => {
      fetchConfiguration()
      fetchTests()
      fetchComponents()
      fetchInterfaces()
    })
    onUnmounted(() => { store.commit('app/CLEAR_DYNAMIC_PAGE_TITLE') })

    // Details pane
    const titleObj = ref({})
    const configObj = ref({
      interfaces: [],
      instances: [],
      components: [],
      tests: [],
    })
    const detailsLoading = ref(false)
    const fetchConfiguration = () => {
      detailsLoading.value = true
      axiosIns
        .get(`/api/v2/domain_model/build_configuration/${configurationId}`)
        .then(response => {
          configObj.value = response.data
          titleObj.value = { name: configObj.value.name, version: configObj.value.version }
          store.commit('app/SET_DYNAMIC_PAGE_TITLE', configObj.value.name)
        })
        .catch(error => {
          context.root.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to fetch Configuration details',
              text: `${error}`,
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => { detailsLoading.value = false })
    }
    const onSaveDetails = () => {
      detailsLoading.value = true
      const payload = {
        name: configObj.value.name,
        version: configObj.value.version,
        effective_from: configObj.value.effective_from,
        description: configObj.value.description,
        components: configObj.value.components.map(x => x.id),
        instances: configObj.value.instances.map(x => x.id),
        interfaces: configObj.value.interfaces.map(x => x.id),
        tests: configObj.value.tests.map(x => x.id),
      }

      axiosIns
        .put(`/api/v2/domain_model/build_configuration/${configurationId}`, payload)
        .then(response => {
          context.root.$toast({
            component: ToastificationContent,
            props: {
              title: 'Updated configuration',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          fetchConfiguration()
        })
        .catch(error => {
          context.root.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to update configuration',
              text: `${error}`,
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => { detailsLoading.value = false })
    }

    // Tests
    const allTests = ref([])
    const fetchTests = () => {
      axiosIns.get('/api/v2/tests', { params })
        .then(({ data }) => {
          allTests.value = data.map(x => ({
            id: x.id,
            ref_id: x.ref_id,
            name: x.name,
          }))
        })
    }
    const linkTests = linkedIds => {
      configObj.value.tests = allTests.value.filter(x => linkedIds.includes(x.id))
      onSaveDetails()
    }

    // Components
    const allComponents = computed(() => store.state.domainModel.components)
    const fetchComponents = () => {
      store.dispatch('domainModel/getComponents')
    }
    const linkComponents = linkedIds => {
      configObj.value.components = allComponents.value.filter(x => linkedIds.includes(x.id))
      onSaveDetails()
    }

    // Interfaces
    const allInterfaces = ref([])
    const fetchInterfaces = () => {
      axiosIns.get('/api/v2/interfaces/get_interfaces_simple', { params })
        .then(response => {
          allInterfaces.value = response.data.map(x => ({
            id: x.interface_properties.id,
            display_id: x.display_id,
            name: x.interface_properties.name,
          }))
        })
    }
    const linkInterfaces = linkedIds => {
      configObj.value.interfaces = allInterfaces.value.filter(x => linkedIds.includes(x.id))
      onSaveDetails()
    }

    // Instances
    // const allInstances = ref([])
    // const fetchInstances = () => {
    //   axiosIns.get('/api/v2/domain_model/get_all_component_instances', { params })
    //     .then(response => {
    //       allInstances.value = response.data.map(x => ({ ...x }))
    //     })
    // }
    // const linkInstances = linkedIds => {
    //   configObj.value.instances = allInstances.value.filter(x => linkedIds.includes(x.id))
    // }

    return {
      titleObj,
      configObj,
      detailsLoading,
      fetchConfiguration,
      onSaveDetails,

      allTests,
      linkTests,

      allComponents,
      linkComponents,

      allInterfaces,
      linkInterfaces,
    }
  },
}
</script>
